import React, { Component } from "react";

import Client from "./Contentful";

import $ from "jquery";

import history from "./components/History";

import { addOrUpdateUrlParam, getUrlParam } from "./utils";

const RoomContext = React.createContext();

class RoomProvider extends Component {
  constructor(props) {
    super(props);

    this.state = {
      rooms: [],
      sortedRooms: [],
      featuredRooms: [],
      loading: true,
      type: "all",
      capacity: 1,
      price: 0,
      minPrice: 0,
      maxPrice: 0,
      minSize: 0,
      maxSize: 0,
      minSizeProp: 0,
      maxSizeProp: 0,
      breakfast: false,
      pets: false,
      isFiltered: false,
      clearPage: false
    };
  }

  async componentDidMount() {
    // fetch data from contentful
    await this.getData();

    // map url params with states
    const queryString = this.props.children.props.history.location.search;
    this.setState(
      {
        type: getUrlParam(queryString, "type") || this.state.type,
        capacity: getUrlParam(queryString, "capacity") || this.state.capacity,
        price: getUrlParam(queryString, "price") || this.state.price,
        minSize: getUrlParam(queryString, "minSize") || this.state.minSize,
        maxSize: getUrlParam(queryString, "maxSize") || this.state.maxSize,
        breakfast:
          (getUrlParam(queryString, "breakfast") &&
            getUrlParam(queryString, "breakfast").toLowerCase() === "true") ||
          this.state.breakfast,
        pets:
          (getUrlParam(queryString, "pets") &&
            getUrlParam(queryString, "pets").toLowerCase() === "true") ||
          this.state.pets,
        isFiltered: queryString ? true : false
      },
      () => {
        $('input[type="range"]').attr("value", this.state.price);
        $('input[type="range"]').rangeslider("update", true);

        this.filterRooms();
      }
    );

    // rangeslider (using jquery)
    $(document).on("change", 'input[type="range"]', (event) => {
      const name = event.target.name;
      const value = event.target.value;

      // map query string
      this.pushParam([name], value);

      this.setState(
        {
          [name]: value,
          isFiltered: true
        },
        this.filterRooms
      );
    });
  }

  getData = async () => {
    try {
      let response = await Client.getEntries({
        content_type: "beachResortRoom",
        order: "fields.price"
      });

      let rooms = this.formatData(response.items);
      let featuredRooms = rooms.filter((room) => room.featured === true);
      let maxPrice = Math.max(...rooms.map((room) => room.price));
      let maxSize = Math.max(...rooms.map((room) => room.size));

      this.initState = {
        ...this.state,
        rooms,
        sortedRooms: rooms,
        featuredRooms,
        loading: false,
        price: maxPrice,
        maxPrice,
        maxSize,
        maxSizeProp: maxSize
      };

      this.setState({ ...this.initState });
    } catch (error) {
      console.log(error);
    }
  };

  formatData(items) {
    // TODO: this.getData()
    let tempItems = items.map((item) => {
      let id = item.sys.id;
      let images = item.fields.images.map((image) => image.fields.file.url);
      let room = { id, ...item.fields, images };
      return room;
    });

    return tempItems;
  }

  getRoom = (slug) => {
    let tempRooms = [...this.state.rooms];
    const room = tempRooms.find((room) => room.slug === slug);
    return room;
  };

  handleChange = (event) => {
    const target = event.target;
    const name = target.name;
    const value = target.type === "checkbox" ? target.checked : target.value;

    // map query string
    this.pushParam([name], value);

    this.setState(
      {
        [name]: value,
        isFiltered: true
      },
      this.filterRooms
    );
  };

  clearFilter = () => {
    history.push({
      pathname: "/rooms",
      search: ""
    });

    this.setState(
      {
        ...this.initState,
        clearPage: true
      },
      () => {
        $('input[type="range"]').attr("value", this.initState.price);
        $('input[type="range"]').rangeslider("update", true);

        this.filterRooms();
      }
    );
  };

  filterRooms = () => {
    let {
      rooms,
      type,
      capacity,
      price,
      minSize,
      maxSize,
      breakfast,
      pets
    } = this.state;

    // all the rooms
    let tempRooms = [...rooms];

    // filter by type
    if (type !== "all")
      tempRooms = tempRooms.filter((room) => room.type === type);

    // filter by capacity
    capacity = parseInt(capacity);
    if (capacity !== 1)
      tempRooms = tempRooms.filter((room) => room.capacity >= capacity);

    // filter by price
    price = parseInt(price);
    tempRooms = tempRooms.filter((room) => room.price <= price);

    // filter by size
    tempRooms = tempRooms.filter(
      (room) => room.size >= minSize && room.size <= maxSize
    );

    // filter by breakfast
    if (breakfast)
      tempRooms = tempRooms.filter((room) => room.breakfast === true);

    // filter by pets
    if (pets) tempRooms = tempRooms.filter((room) => room.pets === true);

    this.setState({
      sortedRooms: tempRooms,
      foundRooms: tempRooms.length,
      clearPage: false
    });
  };

  pushParam(key, value) {
    const queryString = this.props.children.props.history.location.search;
    let uri = addOrUpdateUrlParam(queryString, key, value);
    uri = addOrUpdateUrlParam(uri, "page", 1);

    history.push({
      pathname: "/rooms",
      search: `${uri}`
    });
  }

  render() {
    return (
      <RoomContext.Provider
        value={{
          ...this.state,
          getRoom: this.getRoom,
          handleChange: this.handleChange,
          clearFilter: this.clearFilter
        }}
      >
        {this.props.children}
      </RoomContext.Provider>
    );
  }
}

const RoomConsumer = RoomContext.Consumer;

export function withRoomConsumer(Component) {
  return function ConsumerWrapper(props) {
    return (
      <RoomConsumer>
        {(value) => <Component {...props} context={value} />}
      </RoomConsumer>
    );
  };
}

export { RoomProvider, RoomConsumer, RoomContext };
