import React, { useEffect } from "react";
import { Switch, Route } from "react-router-dom";

import "./App.css";

import $ from "jquery";

import Home from "./pages/Home";
import Rooms from "./pages/Rooms";
import SingleRoom from "./pages/SingleRoom";
import Error from "./pages/Error";

import Footer from "./components/Footer";
import Header from "./components/Header";

function App() {
  useEffect(() => {
    // functions
    var fns = {
      scrollTo: function (elem, offset, speed) {
        $("html,body").animate(
          { scrollTop: $(elem).offset().top - offset },
          speed
        );
      },
      backTop: function (target, offset) {
        $(window).on("scroll", function () {
          if ($(this).scrollTop() - offset > 0) {
            $(target).fadeIn(); // show back top
          } else {
            $(target).fadeOut(); // hide back top
          }
        });
      }
    };

    // toggle nav menu
    $(".hamburger-menu").on("click", function () {
      $(this).toggleClass("open");
    });

    // collapse navbar
    $(".nav-link").on("click", function () {
      $(".navbar-collapse").removeClass("show");
      $(".hamburger-menu").removeClass("open");
    });

    // add back top
    fns.backTop("#backTop", 100);

    // back up
    $("#backTop").on("click", function () {
      fns.scrollTo("body", 0, "slow");
    });

    // stop loading
    setTimeout(function () {
      $("#cover").fadeOut(500);
    }, 500);

    // reveal
    $("#reveal").on("click", function () {
      fns.scrollTo("#services", 0, "slow");
    });
  }, []);

  return (
    <>
      <div className="container-vertical">
        <div className="container-vertical-content">
          <Header />
          <main>
            <Switch>
              <Route exact path="/" component={Home} />
              <Route exact path="/rooms" component={Rooms} />
              <Route exact path="/rooms/:slug" component={SingleRoom} />
              <Route component={Error} />
            </Switch>
          </main>
        </div>
        <Footer />
      </div>
    </>
  );
}

export default App;
