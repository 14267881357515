import React from "react";
import { Link } from "react-router-dom";

import Container from "./Container";

const RoomInfo = ({ room }) => {
  const {
    name,
    description,
    capacity,
    size,
    price,
    extras,
    breakfast,
    pets
  } = room;

  return (
    <Container>
      <div className="d-lg-flex justify-content-between align-items-center pb-3 mt-5 mb-5">
        <div className="mb-4 mb-lg-0">
          <h1 className="font-weight-bold text-capitalize">{name}</h1>
        </div>
        <div className="d-flex justify-content-between align-items-center">
          <Link to="/rooms" className="btn btn-lg btn-primary">
            All Rooms
          </Link>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-4 order-lg-2 mb-5">
          <h4 className="font-weight-bold mb-3">Info</h4>
          <p className="mb-2">Price: ${price}</p>
          <p className="mb-2">Size: {size} SQFT</p>
          <p className="mb-2">
            Max Capacity:{" "}
            {capacity === 1 ? `${capacity} Person` : `${capacity} People`}
          </p>
          <p className="mb-2">Pets {pets ? "" : "Not"} Allowed</p>
          {breakfast && <p className="mb-2">Free Breakfast Included</p>}
        </div>
        <div className="col-lg-8 order-lg-1 mb-5">
          <h4 className="font-weight-bold mb-3">Details</h4>
          {description}
        </div>
      </div>
      <div className="row">
        <div className="col-lg-8 mb-5">
          <h5 className="font-weight-bold mb-3">Extras</h5>
          <ul className="pl-4">
            {extras.map((extra, index) => (
              <li key={index}>{extra}</li>
            ))}
          </ul>
        </div>
      </div>
    </Container>
  );
};

export default RoomInfo;
