import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";

import Loading from "./Loading";
import Container from "./Container";
import RoomsFilter from "./RoomsFilter";
import RoomsList from "./RoomsList";

import { withRoomConsumer } from "../context";
import Pagination from "./Pagination";

import history from "./History";
import { addOrUpdateUrlParam, getUrlParam } from "../utils";

const RoomsContainer = ({ context }) => {
  const { loading, sortedRooms, rooms, clearPage } = context;
  const { search } = useLocation();

  // states
  const [items, setItems] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(process.env.REACT_APP_PER_PAGE || 3);
  const [fromPage, setFromPage] = useState(1);
  const [toPage, setToPage] = useState(itemsPerPage);

  // get current rooms
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = sortedRooms.slice(indexOfFirstItem, indexOfLastItem);

  // change page
  const paginate = (page) => {
    setCurrentPage(page);

    setFromPage((page - 1) * itemsPerPage + 1);
    page * itemsPerPage > items.length
      ? setToPage(items.length)
      : setToPage(page * itemsPerPage);

    const uri = addOrUpdateUrlParam(search, "page", page);
    history.push({
      pathname: "/rooms",
      search: `${uri}`
    });
  };

  useEffect(() => {
    clearPage
      ? setCurrentPage(1)
      : setCurrentPage(parseInt(getUrlParam(search, "page")) || currentPage);

    setFromPage((currentPage - 1) * itemsPerPage + 1);
    currentPage * itemsPerPage > items.length
      ? setToPage(items.length)
      : setToPage(currentPage * itemsPerPage);

    setItems(sortedRooms);
  }, [sortedRooms, search, currentPage, itemsPerPage, items.length, clearPage]);

  if (loading)
    return (
      <div className="mt-5">
        <Loading />
      </div>
    );

  return (
    <>
      <RoomsFilter rooms={rooms} />
      <Container overlap="container-overlap">
        <RoomsList rooms={currentItems} />
      </Container>

      <Container marginTop="mt-4">
        {items.length !== 0 && (
          <div className="d-lg-flex justify-content-lg-between align-items-lg-center">
            <div className="mb-4 mb-lg-0">
              Showing {fromPage} to {toPage} of {items.length}{" "}
              {items.length === 1 ? "room" : "rooms"}
            </div>
            {sortedRooms.length > itemsPerPage && items.length && (
              <>
                <div className="d-none d-lg-block">
                  <Pagination
                    itemsPerPage={itemsPerPage}
                    totalItems={items.length}
                    currentPage={currentPage}
                    paginate={paginate}
                  />
                </div>
                <div className="d-block d-lg-none">
                  <Pagination
                    itemsPerPage={itemsPerPage}
                    totalItems={items.length}
                    currentPage={currentPage}
                    paginate={paginate}
                    theme={"dropdown"}
                  />
                </div>
              </>
            )}
          </div>
        )}
      </Container>
    </>
  );
};

export default withRoomConsumer(RoomsContainer);
