import React from "react";

import FeaturedRooms from "../components/FeaturedRooms";
import HeroBanner from "../components/HeroBanner";
import Services from "../components/Services";

const Home = () => {
  return (
    <>
      <HeroBanner />
      <Services />
      <FeaturedRooms />
    </>
  );
};

export default Home;
