import React, { Component } from "react";

import Container from "./Container";
import Loading from "./Loading";
import Room from "./Room";
import Title from "./Title";

import { RoomContext } from "../context";

export class FeaturedRooms extends Component {
  static contextType = RoomContext;

  render() {
    const { loading, featuredRooms: rooms } = this.context;

    return (
      <Container marginTop="mt-5">
        <Title title="Featured Rooms" />
        {loading ? (
          <Loading />
        ) : (
          <div className="row">
            {rooms.map((room) => {
              return (
                <div className="col-md-6 col-lg-4" key={room.id}>
                  <Room room={room} />
                </div>
              );
            })}
          </div>
        )}
      </Container>
    );
  }
}

export default FeaturedRooms;
