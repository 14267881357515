import React, { useEffect } from "react";
import ReactDOM from "react-dom";
import {
  Router,
  useLocation,
  withRouter
} from "react-router-dom";
import history from "./components/History";

// Theme (CSS/JS)
import "./assets/css/theme.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import "slick-carousel/slick/slick.min.js";
import "rangeslider.js/dist/rangeslider.min.js";

import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { RoomProvider } from "./context";

function _ScrollToTop(props) {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return props.children;
}

const ScrollToTop = withRouter(_ScrollToTop);

ReactDOM.render(
  <RoomProvider>
    <Router history={history}>
      <ScrollToTop>
        <App />
      </ScrollToTop>
    </Router>
  </RoomProvider>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
