import React, { Component } from "react";

import { RoomContext } from "../context";

import RoomSlides from "../components/RoomSlides";
import RoomInfo from "../components/RoomInfo";
import RoomNotFound from "../components/RoomNotFound";

class SingleRoom extends Component {
  constructor(props) {
    super(props);

    this.state = {
      slug: this.props.match.params.slug
    };
  }

  static contextType = RoomContext;

  render() {
    const { getRoom } = this.context;
    const room = getRoom(this.state.slug);

    if (!room) return <RoomNotFound />;

    const { images } = room;

    return (
      <>
        <RoomSlides slides={images} />
        <RoomInfo room={room} />
      </>
    );
  }
}

export default SingleRoom;
