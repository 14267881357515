import React from "react";

const Container = ({ marginTop, marginBottom, overlap, children }) => {
  return (
    <div className={`container ${marginTop} ${marginBottom} ${overlap}`}>{children}</div>
  );
};

Container.defaultProps = {
  marginTop: "",
  marginBottom: "",
  overlap: ""
};

export default Container;
