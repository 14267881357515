import React, { useContext, useEffect } from "react";

import $ from "jquery";

import { capitalize, getUnique } from "../utils";

import { RoomContext } from "../context";

const RoomsFilter = ({ rooms }) => {
  const context = useContext(RoomContext);

  const {
    handleChange,
    clearFilter,
    type,
    capacity,
    price,
    minPrice,
    maxPrice,
    minSize,
    maxSize,
    minSizeProp,
    maxSizeProp,
    breakfast,
    pets,
    isFiltered,
    foundRooms
  } = context;

  // generate room type select options
  let types = getUnique(rooms, "type");
  types = ["all", ...types];
  types = types.map((type, index) => (
    <option value={type} key={index}>
      {capitalize(type)}
    </option>
  ));

  // generate room guests select options
  let people = getUnique(rooms, "capacity");
  people = people.map((person, index) => (
    <option value={person} key={index}>
      {person}
    </option>
  ));

  useEffect(() => {
    // range slider
    $('input[type="range"]').rangeslider({
      polyfill: false
    });
  }, []);

  return (
    <div className="band band-fat bg-light filter-bar">
      <div className="container">
        <div className="d-flex justify-content-between align-items-center mb-lg-5">
          {/* <h4 className="filter-icon d-none d-lg-block">Filter</h4> */}
          <h4 className="d-none d-lg-block">
            <span className="text-primary--">{foundRooms}</span>{" "}
            {`${foundRooms === 1 ? "Room" : "Rooms"} Found`}
          </h4>
          <div
            className={`justify-content-end mb-4 ${
              isFiltered ? "d-none d-lg-block" : "d-none"
            }`}
            id="clear-filter-large"
          >
            <button className="filter-clear" onClick={clearFilter}>
              Clear
            </button>
          </div>
        </div>
        <div className="d-flex justify-content-between align-items-center d-lg-none">
          {/* <h4 className="mb-0 filter-icon">Filter</h4> */}
          <h4 className="mb-0">
            <span className="text-primary--">{foundRooms}</span>{" "}
            {`${foundRooms === 1 ? "Room" : "Rooms"} Found`}
          </h4>
          <button
            type="button"
            className="btn btn-primary btn-block- d-lg-none collapsed"
            aria-label="Toggle navigation"
            aria-expanded="false"
            aria-controls="sidebarNav"
            data-toggle="collapse"
            data-target="#sidebarNav"
          >
            Filter
          </button>
        </div>
        <div
          className="d-lg-flex justify-content-lg-between align-items-lg-center collapse mt-5 mt-lg-0"
          id="sidebarNav"
        >
          <div
            className={`justify-content-end mb-4 ${
              isFiltered ? "d-flex d-lg-none" : "d-none"
            }`}
            id="clear-filter-small"
          >
            <button className="filter-clear" onClick={clearFilter}>
              Clear
            </button>
          </div>
          <div className="d-flex justify-content-start align-items-center">
            {/* begin room type filter */}
            <div className="form-group pl-0 pr-2 pl-lg-0 pr-lg-4 w-100">
              <label htmlFor="type">Room Type</label>
              <select
                className="form-control form-control-lg custom-select custom-select-lg"
                name="type"
                id="type"
                value={type}
                onChange={handleChange}
              >
                {types}
              </select>
            </div>
            {/* begin room type filter */}

            {/* begin room guests filter */}
            <div className="form-group pr-0 pl-2 px-lg-4 w-100">
              <label htmlFor="capacity">Guests</label>
              <select
                className="form-control form-control-lg custom-select custom-select-lg"
                name="capacity"
                id="capacity"
                value={capacity}
                onChange={handleChange}
              >
                {people}
              </select>
            </div>
            {/* end room guests filter */}
          </div>

          {/* begin room price filter */}
          <div className="form-group px-0 px-lg-2 w-lg-25">
            <label htmlFor="price">Room Price (${price})</label>
            <input
              type="range"
              className="custom-range custom-range-lg"
              min={minPrice}
              max={maxPrice}
              name="price"
              id="price"
              value={price}
              onChange={handleChange}
            />
          </div>
          {/* end room price filter */}

          {/* begin room size filter */}
          <div className="form-group px-0 px-lg-2 w-lg-30 w-xl-60">
            <label htmlFor="size">
              Room Size ({minSize}&ndash;{maxSize} SQFT)
            </label>
            <div className="d-flex justify-content-start align-items-center">
              <input
                type="number"
                className="form-control form-control-lg mr-2"
                name="minSize"
                id="size"
                min={minSizeProp}
                max={maxSizeProp}
                value={minSize}
                onChange={handleChange}
              />
              <input
                type="number"
                className="form-control form-control-lg ml-2"
                name="maxSize"
                min={minSizeProp}
                max={maxSizeProp}
                value={maxSize}
                onChange={handleChange}
              />
            </div>
          </div>
          {/* end room size filter */}

          <div className="form-group px-0 pl-lg-2 pr-lg-1 w-lg-15">
            <label className="d-none d-lg-inline-block"></label>
            <div className="d-flex justify-content-start align-items-center d-lg-block">
              {/* begin room breakfast filter */}
              <div className="custom-control custom-checkbox mr-4 mr-lg-0">
                <input
                  type="checkbox"
                  className="custom-control-input"
                  name="breakfast"
                  id="breakfast"
                  checked={breakfast}
                  onChange={handleChange}
                />
                <label className="custom-control-label" htmlFor="breakfast">
                  Breakfast
                </label>
              </div>
              {/* begin room breakfast filter */}

              {/* begin room pets filter */}
              <div className="custom-control custom-checkbox">
                <input
                  type="checkbox"
                  className="custom-control-input"
                  name="pets"
                  id="pets"
                  checked={pets}
                  onChange={handleChange}
                />
                <label className="custom-control-label" htmlFor="pets">
                  Pets
                </label>
              </div>
              {/* begin room pets filter */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RoomsFilter;
