import React from "react";

const Title = ({ marginTop, marginBottom, title }) => {
  return (
    <h1 className={`font-weight-bold text-center ${marginTop} ${marginBottom}`}>
      {title}
    </h1>
  );
};

Title.defaultProps = {
  marginTop: "mt-0",
  marginBottom: "mb-5"
};

export default Title;
