import React from "react";

import RoomsContainer from "../components/RoomsContainer";

const Rooms = () => {
  return (
    <>
      <RoomsContainer />
    </>
  );
};

export default Rooms;
