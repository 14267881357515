import React from "react";
import { Link } from "react-router-dom";
import Container from "../components/Container";

import { ErrorPage } from "../config/data";

const Error = () => {
  return (
    <>
      <Container>
        <h1 className="font-weight-bold mt-5 mb-4">{ErrorPage.title}</h1>
        <p>{ErrorPage.subtitle} </p>
        <Link to={ErrorPage.actionLink} className="btn btn-lg btn-primary mt-5">
          {ErrorPage.actionLabel}
        </Link>
      </Container>
    </>
  );
};

export default Error;
