export const Menus = {
  MainMenu: [
    {
      item: "Home",
      slug: "/"
    },
    {
      item: "Rooms",
      slug: "/rooms"
    }
  ]
};

export const HomeBanner = {
  title: "Luxurious Rooms",
  subtitle: "Deluxe Rooms Starting at $299",
  actionLabel: "Our Rooms",
  actionLink: "/rooms"
};

export const ErrorPage = {
  title: "404 Error!",
  subtitle:
    "The requested URL was not found on the server. If you entered the URL manually please check your spelling and try again.",
  actionLabel: "Back Home",
  actionLink: "/"
};
