import React, { useEffect } from "react";

import $ from "jquery";

const RoomSlides = ({ slides }) => {
  useEffect(() => {
    $(".room-slide").slick({
      dots: false,
      infinite: true,
      autoplay: false,
      speed: 300,
      fade: false,
      slidesToShow: 3,
      slidesToScroll: 1,
      adaptiveHeight: true,
      arrows: true,
      prevArrow: '<span class="slick-arrow slick-prev"></span>',
      nextArrow: '<span class="slick-arrow slick-next"></span>',
      centerMode: true,
      responsive: [
        {
          breakpoint: 1800,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1
          }
        },
        {
          breakpoint: 992,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        },
        {
          breakpoint: 576,
          settings: {
            dots: true,
            arrows: false,
            slidesToShow: 1,
            slidesToScroll: 1,
            centerMode: false
          }
        }
      ]
    });
  }, []);

  return (
    <div className="room-slide">
      {slides.map((image, index) => (
        <div className="mx-0 mx-sm-1 bg-white" key={index}>
          <img className="img-fluid" src={image} alt={`slide${index}`} />
        </div>
      ))}
    </div>
  );
};

export default RoomSlides;
