import React from "react";
import { Link } from "react-router-dom";

import Container from "./Container";

import { HomeBanner } from "../config/data";

const HeroBanner = ({ blurry, reveal }) => {
  return (
    <div className="hero-banner justify-content-center align-items-center">
      {blurry && <div className="blurry-layer"></div>}
      {reveal && <div className="reveal" id="reveal"></div>}
      <Container>
        <div className="card card-tranparent px-1 pt-5 pb-4 border-0">
          <div className="card-body text-white text-center">
            <h1 className="display-2 font-weight-bold mb-4 d-none d-md-block">
              {HomeBanner.title}
            </h1>
            <h1 className="display-4 font-weight-bold mb-4 d-block d-md-none">
              {HomeBanner.title}
            </h1>
            <p className="h3 font-weight-light mb-5">{HomeBanner.subtitle}</p>
            <Link to={HomeBanner.actionLink} className="btn btn-lg btn-primary">
              {HomeBanner.actionLabel}
            </Link>
          </div>
        </div>
      </Container>
    </div>
  );
};

HeroBanner.defaultProps = {
  blurry: true,
  reveal: true
};

export default HeroBanner;
